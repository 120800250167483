// View to contain the order map.  It does a number of things, including:
//    - handles origin, destination, and driver location updates (repainting
//      the directions and the markers for the pins)
//    - attempts to intelligently fit the relevant pieces of data on the map

import $ from 'jquery';
import _ from 'lodash';

class OrderMap {
  constructor() {
    this.directionsDisplay = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
      preserveViewport: true
    });
  }

  init(view) {
    this.$view = $(view);
    this.$originField = $(this.$view.data('origin-source')).first();
    this.$destinationField = $(this.$view.data('destination-source')).first();

    this.clearOrigin = this.clearOrigin.bind(this);
    this.clearDestination = this.clearDestination.bind(this);
    this.handleOriginUpdated = this.handleOriginUpdated.bind(this);
    this.handleDestinationUpdated = this.handleDestinationUpdated.bind(this);
    this.fitMap = this.fitMap.bind(this);

    this.$view.map = new google.maps.Map(this.$view.find('.js-map')[0], {
      gestureHandling: 'cooperative',
      scrollwheel: false
    });

    this.$view.origin = this.constructGoogleLatLng(
      this.$view.data('origin-lat'),
      this.$view.data('origin-lng')
    );

    this.$view.destination = this.constructGoogleLatLng(
      this.$view.data('destination-lat'),
      this.$view.data('destination-lng')
    );

    this.$view.driverLocation = this.constructGoogleLatLng(
      this.$view.data('driver-lat'),
      this.$view.data('driver-lng')
    );

    this.directionsDisplay.setMap(this.$view.map);
    var trafficLayer = new google.maps.TrafficLayer();
    trafficLayer.setMap(this.$view.map);

    // Handle window resizing.
    $(window).resize(
      function() {
        google.maps.event.trigger(this.$view.map, 'resize');
      }.bind(this)
    );

    // Draw the map
    this.drawMap();

    // Handle origin updates
    this.$originField
      .closest('.js-geocode')
      .on('geocode-complete', this.handleOriginUpdated);
    this.$originField.on('autocomplete-complete', this.handleOriginUpdated);

    // Handle destination updates
    this.$destinationField
      .closest('.js-geocode')
      .on('geocode-complete', this.handleDestinationUpdated);
    this.$destinationField.on(
      'autocomplete-complete',
      this.handleDestinationUpdated
    );

    // Handle driver updates
    $(document).on(
      'driver-location-change',
      function(e, location) {
        this.$view.data('driver-lat', location.lat);
        this.$view.data('driver-lng', location.lng);

        this.$view.driverLocation = this.constructGoogleLatLng(
          location.lat,
          location.lng
        );

        this.drawDriverMarker();
      }.bind(this)
    );

    this.$originField
      .closest('.js-geocode')
      .on('location-cleared', this.clearOrigin);
    this.$destinationField
      .closest('.js-geocode')
      .on('location-cleared', this.clearDestination);

    // Handle directions events
    $(document).on(
      'directions-available',
      function(e, directionsResponse) {
        this.directionsDisplay.setDirections(directionsResponse);
        this.$view.directionsResponse = directionsResponse;
      }.bind(this)
    );

    $(document).on('resize-maps', this.fitMap);
  }

  clearOrigin() {
    this.$view.origin = null;
    if (this.$view.originMarker) {
      this.$view.originMarker.setMap(null);
    }
    this.drawMap();
    this.setMapVisibility();
  }

  clearDestination() {
    this.$view.destination = null;
    if (this.$view.destinationMarker) {
      this.$view.destinationMarker.setMap(null);
    }
    this.drawMap();
    this.setMapVisibility();
  }

  handleOriginUpdated(e, data) {
    if (data) {
      this.$view.origin = new google.maps.LatLng(data);
      this.drawMap();
      return;
    }

    this.clearOrigin();
  }

  handleDestinationUpdated(e, data) {
    if (data) {
      this.$view.destination = new google.maps.LatLng(data);
      this.drawMap();
      return;
    }

    this.clearDestination();
  }

  // Set the visibility of the map, which should be hidden if there is nothing
  // to show.
  // Note: Due to a bug with gmaps and displaying the map as grey on mobile, we had to change
  // how we were hiding the map in order to get the map to show on initial load. This led to
  // another issue of rendering a large white space on the old order form. Another div was
  // added and now successfully hides the map. However, it is important to note this could cause issues
  // in future iterations of the order form and rendering the map.
  setMapVisibility() {
    var deferred = $.Deferred();

    // Show the map if there's anything to show
    if (
      this.$view.origin ||
      this.$view.destination ||
      this.$view.driverLocation
    ) {
      this.$view.removeClass('visibility-hidden');
      // Google requires that we trigger a resize event because the div size
      // changes
      google.maps.event.trigger(this.$view.map, 'resize');
      deferred.resolve();
    } else {
      this.$view.addClass('visibility-hidden');
      deferred.resolve();
    }

    return deferred;
  }

  // Main entrypoint to draw the map overall.
  drawMap() {
    this.drawOriginMarker();
    this.drawDestinationMarker();
    this.drawDriverMarker();
    _.debounce(this.fitMap, 200)();
  }

  handleDirectionsResult(response, status) {
    if (status === 'OK') {
      this.directionsDisplay.setDirections(response);
      this.$view.directionsResponse = response;
    }
  }

  // Helper to construct a Google coordinate object based on a provided
  // latitude and longitude
  constructGoogleLatLng(latString, lngString) {
    var lat = parseFloat(latString);
    var lng = parseFloat(lngString);

    // Return early if lat/lng are not set
    if (isNaN(lat) || isNaN(lng)) {
      return undefined;
    }

    return (this.$view.driverLocation = new google.maps.LatLng({
      lat: lat,
      lng: lng
    }));
  }

  // Draws the driver marker, if it needs to be drawn.
  drawDriverMarker() {
    // Do nothing if there is no driver location
    if (this.$view.driverLocation === undefined) {
      return;
    }

    if (this.$view.driverMarker === undefined) {
      this.$view.driverMarker = new google.maps.Marker(
        this.driverMarkerOptions()
      );
    }

    this.$view.driverMarker.setPosition(this.$view.driverLocation);
    this.$view.driverMarker.setMap(this.$view.map);
  }

  // Draws the origin marker, if it needs to be drawn.
  drawOriginMarker() {
    // Do nothing if there is no origin
    if (this.$view.origin === undefined) {
      return;
    }

    // Initialize the marker if it doesn't already exist
    if (this.$view.originMarker === undefined) {
      this.$view.originMarker = new google.maps.Marker(
        this.originMarkerOptions()
      );
    }

    this.$view.originMarker.setPosition(this.$view.origin);
    this.$view.originMarker.setMap(this.$view.map);
  }

  // Draws the destination marker, if it needs to be drawn.
  drawDestinationMarker() {
    // Do nothing if there is no destination
    if (this.$view.destination === undefined) {
      return;
    }

    // Initialize the marker if it doesn't already exist
    if (this.$view.destinationMarker === undefined) {
      this.$view.destinationMarker = new google.maps.Marker(
        this.destinationMarkerOptions()
      );
    }

    this.$view.destinationMarker.setPosition(this.$view.destination);
    this.$view.destinationMarker.setMap(this.$view.map);
  }

  // Grab all the locations out of the route calculated for this view.  It will
  // be used for map fitting.
  locationsInRoute() {
    var locations = [];
    var directionsResponse = this.$view.directionsResponse;

    if (directionsResponse === undefined) {
      return [];
    }

    for (var j = 0; j < directionsResponse.routes.length; j++) {
      for (var k = 0; k < directionsResponse.routes[j].legs.length; k++) {
        locations.push(
          new google.maps.LatLng({
            lat: directionsResponse.routes[j].legs[k].start_location.lat(),
            lng: directionsResponse.routes[j].legs[k].start_location.lng()
          })
        );
        locations.push(
          new google.maps.LatLng({
            lat: directionsResponse.routes[j].legs[k].end_location.lat(),
            lng: directionsResponse.routes[j].legs[k].end_location.lng()
          })
        );
      }
    }
    return locations;
  }

  // Fit the map based on the locations relevant to this map.  Debounce calls
  // to the function because it is called so often.
  fitMap() {
    let locationsToShow = [];

    if (this.$view.origin) {
      locationsToShow.push(this.$view.origin);
    }

    if (this.$view.destination) {
      locationsToShow.push(this.$view.destination);
    }

    if (this.$view.driverLocation) {
      locationsToShow.push(this.$view.driverLocation);
    }

    // add all the locations associated with the directions route
    if (this.$view.directionsResponse) {
      locationsToShow.push.apply(locationsToShow, this.locationsInRoute());
    }

    var bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < locationsToShow.length; i++) {
      bounds.extend(locationsToShow[i]);
    }

    // Don't zoom in too far on only one marker
    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      var extendPoint1 = new google.maps.LatLng(
        bounds.getNorthEast().lat() + 0.005,
        bounds.getNorthEast().lng() + 0.005
      );
      var extendPoint2 = new google.maps.LatLng(
        bounds.getNorthEast().lat() - 0.005,
        bounds.getNorthEast().lng() - 0.005
      );
      bounds.extend(extendPoint1);
      bounds.extend(extendPoint2);
    }

    this.setMapVisibility().then(this.$view.map.fitBounds(bounds));
  }

  destinationMarkerOptions() {
    return {
      title: 'Drop-Off Location',
      icon: {
        url: this.$view.data('destination-icon'),
        anchor: new google.maps.Point(4, 65),
        scaledSize: new google.maps.Size(42, 68)
      }
    };
  }

  originMarkerOptions() {
    return {
      title: 'Pickup Location',
      icon: {
        url: this.$view.data('origin-icon'),
        anchor: new google.maps.Point(38, 65),
        scaledSize: new google.maps.Size(42, 68)
      }
    };
  }

  driverMarkerOptions() {
    return {
      title: 'Driver',
      icon: {
        url: this.$view.data('driver-icon'),
        anchor: new google.maps.Point(4, 65),
        scaledSize: new google.maps.Size(42, 68)
      }
    };
  }
}

// Initialize order map views
$(document).ready(() => {
  $('.js-order-map').each(function(i, element) {
    var map = new OrderMap();
    map.init(element);
  });
});
