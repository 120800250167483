import $ from 'jquery';
import { analyticsTrack } from './src/utils';

$(document).ready(function() {
  $('.showTerms').click(() => {
    const termsField = document.getElementById('terms_guid');
    let termsGuid = null;
    if (termsField) {
      termsGuid = termsField.value;
    }

    analyticsTrack('Terms & Conditions Modal Viewed', {
      guid: termsGuid
    });

    $('#termsModal').modal('show');
  });
});
