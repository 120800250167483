// This keeps fields that are annotated with the data-sync attribute
// in sync.  Annotate the field with `data-sync="key-used-to-match"`

import $ from 'jquery';

class FieldSync {
  constructor() {
    this.setAllKeys();
    this.setUpSync();
  }

  setAllKeys() {
    this.keys = [];

    // Get all the keys and de-duplicate so we can iterate over each
    // to sync them in groups.
    $('[data-sync]').each((i, el) => {
      const $el = $(el);
      const syncKey = $el.attr('data-sync');

      if (this.keys.indexOf(syncKey) === -1) {
        this.keys.push(syncKey);
      }
    });
  }

  setUpSync() {
    $(this.keys).each((i, key) => {
      const $elements = $('[data-sync=' + key + ']');

      const $radioElements = $elements.filter(':radio');
      $radioElements.on('change', e => {
        const newValue = $(e.target).prop('checked');

        // We only want to change elements that don't already match,
        // so we don't trigger an endless loop when triggering the `change`
        // event on them.
        const $elementsToProcess = $radioElements.filter((i, element) => {
          return $(element).prop('checked') !== newValue;
        });

        // Trigger the change event after updating the value!
        $elementsToProcess.prop('checked', newValue).change();
      });

      const $nonRadioElements = $elements.filter(':not(:radio)');
      $nonRadioElements.on('change', e => {
        const newValue = $(e.target).val();

        // We only want to change elements that don't already match,
        // so we don't trigger an endless loop when triggering the `change`
        // event on them.
        const $elementsToProcess = $nonRadioElements.filter((i, element) => {
          return $(element).val() !== newValue;
        });

        // Trigger the change event after updating the value!
        $elementsToProcess.val(newValue).change();
      });
    });
  }
}

$(document).ready(() => {
  new FieldSync();
});
