import $ from 'jquery';
import getAddressObjectFromPlace from './googleUtils/getAddressObjectFromPlace';
import LocationAutocomplete from './components/LocationAutocomplete';
import React from 'react';
import ReactDOM from 'react-dom';

class GooglePlacesAutocomplete {
  constructor(view) {
    this.$view = $(view);
    this.setFields();
    this.buildAutocomplete();
    this.previousPhoneNumber = '';

    this.processPlace = this.processPlace.bind(this);
    this.removePlace = this.removePlace.bind(this);
  }

  buildAutocomplete() {
    const inputClasses = this.$input.attr('class');
    const inputValue = this.$input.val();
    const inputName = this.$input.attr('name');
    const autoCompleteValue = this.$input.attr('data-autoComplete');
    const options = this.$view.data('options') || {};
    const placeholderField =
      this.$view.attr('data-placeholderField') ||
      'Type an address or business name';
    const savedLocations = options.saved_locations || [];
    const recentLocations = options.recent_locations || [];

    this.$wrapper = $('<div></div>');
    this.$input.after(this.$wrapper);
    this.$input.remove();
    ReactDOM.render(
      <LocationAutocomplete
        inputClasses={inputClasses}
        inputValue={inputValue}
        inputName={inputName}
        autoCompleteValue={autoCompleteValue}
        initialPlaceId={this.$place_id.val()}
        placeholder={placeholderField}
        savedLocations={savedLocations}
        recentLocations={recentLocations}
        $wrapper={this.$wrapper}
        onSelect={(inputValue, item) => {
          if (item) {
            this.processPlace(inputValue, item);
            return;
          }

          // there is no item, we want to clear the fields
          this.removePlace();
        }}
      />,
      this.$wrapper[0]
    );
  }

  setFields() {
    this.$input = this.$view.find('.js-GooglePlacesAutocomplete-input');
    this.$place_id = this.$view.find('.js-GooglePlacesAutocomplete-place_id');
    this.$description = this.$view.find(
      '.js-GooglePlacesAutocomplete-description'
    );
    this.$geo_lat = this.$view.find('.js-GooglePlacesAutocomplete-geo_lat');
    this.$geo_lng = this.$view.find('.js-GooglePlacesAutocomplete-geo_lng');
    this.$country = this.$view.find('.js-GooglePlacesAutocomplete-country');
    this.$name = this.$view.find('.js-GooglePlacesAutocomplete-name');
    this.$phone = this.$view.find('.js-GooglePlacesAutocomplete-phone');
    this.$street = this.$view.find('.js-GooglePlacesAutocomplete-street');
    this.$neighborhood = this.$view.find(
      '.js-GooglePlacesAutocomplete-neighborhood'
    );
    this.$city = this.$view.find('.js-GooglePlacesAutocomplete-city');
    this.$state = this.$view.find('.js-GooglePlacesAutocomplete-state');
    this.$postal_code = this.$view.find(
      '.js-GooglePlacesAutocomplete-postal_code'
    );
    this.$default_notes = this.$view.find(
      '.js-GooglePlacesAutocomplete-default_notes'
    );
    this.$saved_location_label = this.$view.find(
      '.js-GooglePlacesAutocomplete-saved_location_label'
    );
    this.$message = $('<span class="hidden help-block"></span>');
    this.$input.after(this.$message);
    this.$phoneMessage = $('<span class="hidden help-block"></span>');
    this.$phone.after(this.$phoneMessage);
    this.$default_notes_pickup_preview = $(
      '.js-GooglePlacesAutocomplete-default_notes_pickup_preview'
    );
    this.$default_notes_drop_off_preview = $(
      '.js-GooglePlacesAutocomplete-default_notes_drop_off_preview'
    );
    this.$mapDescriptionContainer = $(this.$view.find('.js-order-map')[0]);
    this.$dropOffDescription = $(
      this.$view.find('.js-map-drop-off-description')[0]
    );
    this.$pickupDescription = $(
      this.$view.find('.js-map-pickup-description')[0]
    );
  }

  removePlace() {
    this.removeErrorMessage();
    this.$description.val('');
    this.$place_id.val('');
    this.$geo_lat.val('');
    this.$geo_lng.val('');
    this.$country.val('');
    this.$name.val('');
    this.$phone.val('');
    this.$street.val('');
    this.$neighborhood.val('');
    this.$city.val('');
    this.$state.val('');
    this.$postal_code.val('');
    this.$view.trigger('autocomplete-complete');
  }

  processPlace(inputValue, place) {
    this.removeErrorMessage();

    let previewHtml =
      `<b>Default notes for this address </b>` +
      `<div class="default-notes-text"></div>`;

    if (this.$input[0].id.includes('pickup')) {
      if (place.default_notes) {
        this.$default_notes_pickup_preview.html(previewHtml);
        this.$default_notes_pickup_preview
          .children('.default-notes-text')
          .text(place.default_notes);
      } else {
        this.$default_notes_pickup_preview.html('');
      }
    }
    if (this.$input[0].id.includes('drop_off')) {
      if (place.default_notes) {
        this.$default_notes_drop_off_preview.html(previewHtml);
        this.$default_notes_drop_off_preview
          .children('.default-notes-text')
          .text(place.default_notes);
      } else {
        this.$default_notes_drop_off_preview.html('');
      }
    }

    this.$description.val(inputValue);
    this.$place_id.val(place.place_id);

    if (place.geometry) {
      this.$geo_lat.val(place.geometry.location.lat());
      this.$geo_lng.val(place.geometry.location.lng());
    } else {
      this.setErrorMessage(
        'The location for this is missing. Please try another.'
      );
      return;
    }

    const address = getAddressObjectFromPlace(place);
    this.$country.val(address.country);
    this.$name.val(address.name);
    if (
      this.$phone.val() !== '' &&
      this.$phone.val() === address.phone_number
    ) {
      this.previousPhoneNumber = this.$phone.val();
    } else if (
      this.$phone.val() !== '' &&
      this.$phone.val() !== address.phone_number &&
      address.phone_number !== this.previousPhoneNumber &&
      this.previousPhoneNumber === ''
    ) {
      this.previousPhoneNumber = this.$phone.val();
    } else if (
      this.$phone.val() !== '' &&
      this.$phone.val() !== address.phone_number &&
      address.phone_number !== this.previousPhoneNumber &&
      this.previousPhoneNumber !== ''
    ) {
      this.previousPhoneNumber = this.$phone.val(address.phone_number);
      this.$phone.val(address.phone_number);
    } else {
      this.$phone.val(address.phone_number);
    }
    this.$street.val(address.street);
    this.$neighborhood.val(address.neighborhood);
    this.$city.val(address.city);
    this.$state.val(address.state);
    this.$postal_code.val(address.zip);
    this.$default_notes.val(place.default_notes);
    this.$saved_location_label.val(place.saved_location_label);

    this.$view.trigger('autocomplete-complete', {
      place_id: place.place_id,
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      place
    });

    // this.handlePhoneMessaging(address.phone_number);
    this.validateAddress(address);
  }

  validateAddress(address) {
    if (
      !address.country ||
      !address.street ||
      !address.city ||
      !address.state ||
      !address.zip
    ) {
      this.setErrorMessage('Please enter a more specific location.');
    }
  }

  setErrorMessage(message) {
    this.$message.html(message).removeClass('hidden');
    this.$wrapper.closest('.form-group').addClass('has-error');
  }

  setHelperMessage(message) {
    this.removeErrorMessage();
    this.$message.html(message).removeClass('hidden');
  }

  removeErrorMessage() {
    this.$message.addClass('hidden');
    this.$wrapper
      .closest('.form-group')
      .removeClass('has-error')
      .find('.error-message')
      .remove();
  }
}

$(() => {
  $('.js-GooglePlacesAutocomplete').each((i, item) => {
    new GooglePlacesAutocomplete(item);
  });
});
