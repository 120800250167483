import OrderDirections from './order_directions';
import _ from 'lodash';

class OrderMapUpdater {
  constructor(view) {
    this.config = $(view).data();

    const origin = new google.maps.LatLng({
      lat: parseFloat(this.config.directionsProperties.origin.lat),
      lng: parseFloat(this.config.directionsProperties.origin.lng)
    });
    const destination = new google.maps.LatLng({
      lat: parseFloat(this.config.directionsProperties.destination.lat),
      lng: parseFloat(this.config.directionsProperties.destination.lng)
    });
    const waypoints = _.map(this.config.directionsProperties.waypoints, i => {
      return {
        location: new google.maps.LatLng({
          lat: parseFloat(i.lat),
          lng: parseFloat(i.lng)
        })
      };
    });

    this.orderDirections = new OrderDirections({
      origin: origin,
      destination: destination,
      waypoints: waypoints
    });

    this.handlePageUpdates = this.handlePageUpdates.bind(this);
    this.retrievePageUpdates = this.retrievePageUpdates.bind(this);

    _.delay(
      this.retrievePageUpdates,
      this.config.driverLocationRefreshInterval
    );
  }

  handlePageUpdates(result) {
    this.orderDirections.setProperties(result.order);
    this.orderDirections.run();
    $(document).trigger('driver-location-change', result.order.driver_location);

    if (result.seconds_until_refresh) {
      _.delay(this.retrievePageUpdates, result.seconds_until_refresh * 1000);
    }
  }

  retrievePageUpdates() {
    $.get(this.config.statusUrl, this.handlePageUpdates);
  }
}

$(() => {
  $('#js-OrderMapUpdater').each((i, item) => {
    new OrderMapUpdater(item);
  });
});
