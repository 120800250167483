import $ from 'jquery';
import moment from 'moment';

// Module to run the Google Directions service and publish events to notify
// other modules that it has completed.
class OrderDirections {
  constructor(properties) {
    this.handleResult = this.handleResult.bind(this);
    this.directionsService = new google.maps.DirectionsService();
    this.setProperties(properties);
    this.run();
  }

  formatDuration(momentTime) {
    let str = '';

    if (momentTime.days() > 0) {
      if (momentTime.days() === 1) {
        str = str + Math.floor(momentTime.days()) + ' day ';
      } else {
        str = str + Math.floor(momentTime.days()) + ' days ';
      }
    }

    if (momentTime.hours() > 0) {
      if (momentTime.hours() === 1) {
        str = str + Math.floor(momentTime.hours()) + ' hour ';
      } else {
        str = str + Math.floor(momentTime.hours()) + ' hours ';
      }
    }

    if (momentTime.minutes() > 0) {
      if (momentTime.minutes() === 1) {
        str = str + Math.floor(momentTime.minutes()) + ' minute ';
      } else {
        str = str + Math.floor(momentTime.minutes()) + ' minutes ';
      }
    }

    // Add a placeholder for the scenario where time is so small that it
    // is just a matter of seconds.
    if (
      momentTime.days() === 0 &&
      momentTime.hours() === 0 &&
      momentTime.minutes() === 0
    ) {
      return 'less than a minute';
    }

    return str;
  }

  setProperties(properties) {
    this.origin = properties.origin;
    this.destination = properties.destination;
    this.waypoints = properties.waypoints;
  }

  run() {
    this.directionsService.route(
      {
        origin: this.origin,
        destination: this.destination,
        waypoints: this.waypoints,
        travelMode: google.maps.TravelMode.DRIVING,
        drivingOptions: {
          departureTime: new Date()
        }
      },
      this.handleResult.bind(this)
    );
  }

  handleResult(response, status) {
    if (status === google.maps.DirectionsStatus.OK) {
      this.sendNotifications(response);
      this.setETA(response);
    } else {
      // eslint-disable-next-line no-console
      console.log(status, response);
    }
  }

  sendNotifications(data) {
    $(document).trigger('directions-available', data);
  }

  setETA(data) {
    let duration = 0;
    for (var i = 0; i < data.routes[0].legs.length; i++) {
      const leg = data.routes[0].legs[i];

      if (leg.duration_in_traffic) {
        duration += leg.duration_in_traffic.value;
      } else {
        duration += leg.duration.value;
      }
    }

    const durationText = this.formatDuration(
      moment.duration(duration, 'seconds')
    );
    $('.js-eta-text').html(durationText);
  }
}

export default OrderDirections;
