$(() => {
  // Form controls for admin organization settings page

  // Show a warning banner if a user tries to removed invoiced billing
  // from an organization with credits
  if ($('.js-invoice-credit-warning-checkbox')) {
    const $invoicedCheckbox = $('.js-invoice-credit-warning-checkbox');
    const $creditsWarningBanner = $('#invoice-credit-warning');
    const isCheckedInitially = $invoicedCheckbox.is(':checked');

    if (isCheckedInitially) {
      $($invoicedCheckbox).on('change', e => {
        const $target = $(e.currentTarget);
        if ($target.is(':not(:checked)')) {
          $creditsWarningBanner.show();
        } else {
          $creditsWarningBanner.hide();
        }
      });
    }
  }
});
