import $ from 'jquery';
import 'jqueryDateTimePicker';
import moment from 'moment-timezone';
import 'timepicker';

// Set the default timezone for our javascript datetime components
moment.tz.setDefault(moment.tz.guess());

$(document).ready(() => {
  // Datetime pickers
  $('.js-datetimepicker').datetimepicker({
    format: window.moment_js_time_format
  });

  // Date-only picker
  $('.js-datepicker').datetimepicker({
    format: 'M/D/YYYY'
  });

  // Date-only picker
  $('.js-datepicker-day-first').datetimepicker({
    format: 'D/M/YYYY'
  });

  // Time-only picker
  $('.js-timepicker').timepicker({
    scrollDefault: 'now',
    timeFormat: 'g:iA'
  });
});
