export default class AdjustmentAmount {
  constructor() {
    this.reason;
    this.order_id;
    this.mileage_holder;
    this.og_amount = $('#adjustment-amount-input').val();
    this.og_driver_amount = $('#adjustment-driver-amount-input').val();
  }

  set_values = (reason, order_id, mileage, precise_weight) => {
    this.reason = reason;
    this.order_id = order_id;
    this.mileage_holder = mileage;
    this.precise_weight_holder = precise_weight;
  };

  process() {
    switch (this.reason) {
      case 'labor_fee':
        $(`#order_price_adjustment_${this.reason}_option`).on('change', e => {
          e.preventDefault();
          this.update_adjustment_amounts(e.target.value);
        });
        $(`#order_price_adjustment_${this.reason}_input`).on('change', e => {
          e.preventDefault();
          this.precise_weight_holder = e.target.value;
        });
        break;
      case 'needed_assistance':
        $(`#order_price_adjustment_${this.reason}_option`).on('change', e => {
          e.preventDefault();
          this.update_adjustment_amounts(e.target.value);
        });
        break;
      case 'order_cancelled':
        this.update_adjustment_amounts();
        break;
      case 'vehicle_change':
      case 'service_change':
        $(`#order_price_adjustment_${this.reason}_option`).on('change', e => {
          e.preventDefault();
          this.update_adjustment_amounts(e.target.value);
        });
        break;
      case 'mileage_change':
      case 'return_trip':
        $(`#order_price_adjustment_${this.reason}_input`).on('change', e => {
          e.preventDefault();
          this.mileage_holder = e.target.value;
        });
        break;
      default:
        $(`#order_price_adjustment_${this.reason}_input`).on('change', e => {
          e.preventDefault();
          this.update_adjustment_amounts(e.target.value);
        });
    }
    this.show_input();
    this.rehide_unused_inputs();
  }

  show_input = () => {
    $(`#adjustment_${this.reason}_input_option`).show();
    if (this.reason === 'labor_fee') {
      $('#adjustment_labor_fee_button_option').css({
        display: 'block',
        'margin-bottom': '5px'
      });
    }
    if (this.reason === 'mileage_change') {
      $('#adjustment_mileage_change_button_option').css({
        display: 'block',
        'margin-bottom': '5px'
      });
    }
    if (this.reason === 'return_trip') {
      $('#adjustment_return_trip_button_option').css({
        display: 'block',
        'margin-bottom': '5px'
      });
    }
  };

  confirm_precise_weight = () => {
    this.update_adjustment_amounts(this.precise_weight_holder);
  };

  confirm_mileage = () => {
    this.update_adjustment_amounts(this.mileage_holder);
  };

  update_adjustment_amounts = (val = 'none') => {
    fetch(`/orders/${this.order_id}/order_price_adjustment/estimate`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': $('view')
          .find('[name="authenticity_token"]')
          .val()
      },
      body: JSON.stringify({
        reason: this.reason,
        value: val
      })
    })
      .then(response => response.json())
      .then(json => {
        this.new_amount_values(json);
      });
  };

  new_amount_values = data => {
    $('#adjustment-amount-input').val(parseFloat(data.amount).toFixed(2));
    $('#adjustment-driver-amount-input').val(
      parseFloat(data.driver_amount).toFixed(2)
    );
  };

  rehide_unused_inputs = () => {
    if (this.reason !== 'vehicle_change') {
      $('#adjustment_vehicle_change_input_option').hide();
    }
    if (this.reason !== 'toll') {
      $('#adjustment_toll_input_option').hide();
    }
    if (this.reason !== 'mileage_change') {
      $('#adjustment_mileage_change_button_option').hide();
      $('#adjustment_mileage_change_input_option').hide();
    }
    if (this.reason !== 'service_change') {
      $('#adjustment_service_change_input_option').hide();
    }
    if (this.reason !== 'driver_bonus') {
      $('#adjustment_driver_bonus_input_option').hide();
    }
    if (this.reason !== 'labor_fee') {
      $('#adjustment_labor_fee_input_option').hide();
    }
    if (this.reason !== 'needed_assistance') {
      $('#adjustment_needed_assistance_input_option').hide();
    }
    if (this.reason !== 'add_add_on') {
      $('#adjustment_add_add_on_input_option').hide();
    }
    if (this.reason !== 'remove_add_on') {
      $('#adjustment_remove_add_on_input_option').hide();
    }
    if (this.reason !== 'return_trip') {
      $('#adjustment_return_trip_button_option').hide();
      $('#adjustment_return_trip_input_option').hide();
    }
  };
}
