$(() => {
  // Allows typing a decimal number starting from the 100ths place.
  // e.g. in an empty field, typing '1' will result in '0.01'
  // Currently somewhat works with negative numbers but the experience could be better

  $(document).on('input', '.js-currency-input', e => {
    var target = e.target;
    var value = target.value;
    var isNegative = /^(\s*-)/.test(value);

    // allows someone to type in a hyphen before any digits
    if (value === '-') {
      target.value = value;
      return;
    }

    if (value.length === 1) {
      value = '0' + value;
    }

    value = parseFloat(
      value.replace(/[^\d]/g, '').replace(/(\d\d?)$/, '.$1')
    ).toFixed(2);

    value = isNegative ? value * -1 : value;

    // Make sure NaN doesn't show up in the field
    target.value = isNaN(value) ? '' : value;
  });
});
