import $ from 'jquery';

$(() => {
  const flatFeeRadio = $('#capability_price_type_flat_fee');
  const percentRadio = $('#capability_price_type_percentage_of_total');
  const priceAmountInput = $('#capability_price_amount');
  const pricePercentageInput = $('#capability_price_percentage');
  const priceAmountWrapper = $('#capability_price_amount_wrapper');
  const pricePercentageWrapper = $('#capability_price_percentage_wrapper');

  if (
    flatFeeRadio.length === 0 ||
    percentRadio.length === 0 ||
    priceAmountInput.length === 0 ||
    pricePercentageInput.length === 0 ||
    priceAmountWrapper.length === 0 ||
    pricePercentageWrapper.length === 0
  ) {
    return;
  }

  let priceAmountVal = priceAmountInput.val();
  let pricePercentageVal = pricePercentageInput.val();

  flatFeeRadio.on('change', () => {
    if (!flatFeeRadio.prop('checked')) return;

    pricePercentageVal = pricePercentageInput.val();
    pricePercentageInput.prop('value', '');
    priceAmountInput.prop('value', priceAmountVal);
    pricePercentageWrapper.hide();
    priceAmountWrapper.show();
  });

  percentRadio.on('change', () => {
    if (!percentRadio.prop('checked')) return;

    priceAmountVal = priceAmountInput.val();
    priceAmountInput.prop('value', '');
    pricePercentageInput.prop('value', pricePercentageVal);
    priceAmountWrapper.hide();
    pricePercentageWrapper.show();
  });

  flatFeeRadio.trigger('change');
  percentRadio.trigger('change');
});
