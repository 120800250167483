import $ from 'jquery';

$(document).ready(() => {
  if (
    $('js-self-provisioning-referral-company-referred-box') &&
    $('js-self-provisioning-referral-company-referred-box').prop('checked')
  ) {
    $('#js-self-provisioning-referral-company-input-wrapper').show();
  } else {
    $('#js-self-provisioning-referral-company-input-wrapper').hide();
  }

  $('#js-self-provisioning-referral-company-referred-box').on('click', e => {
    if (e.target.checked) {
      $('#js-self-provisioning-referral-company-input-wrapper').show();
    } else {
      $('#js-self-provisioning-referral-company-input-wrapper').hide();
    }
  });
});
