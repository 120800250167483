// Detects the timezone based on the user's browser, and sets it in a cookie for
// usage by the server.

import $ from 'jquery';
import moment from 'moment-timezone';
import Cookies from 'js-cookie';

$(document).ready(() => {
  Cookies.set('browser.timezone', moment.tz.guess(), {
    expires: 365,
    path: '/'
  });
});
