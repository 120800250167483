// TODO: whoever works on this file next, delete this comment block
//       to re-enable eslint for this file and fix the issues
//
/* eslint-disable no-unused-vars */
// It looks like this code might not work how it's supposed to; the function is using the `e` param
// but the var `form` is being set using `event` which isn't specified
//
//

// Automatically submits the (typically hidden) form button to perform the upload, whenever files
// are chosen. The pre-built direct upload UI is sufficient for most usecases, but it waits for the
// user to actually click a button to start the upload (even though they've already chosen the files
// to upload).
$(document).on('change', '.js-autoupload', e => {
  const form = $(event.target).closest('form');

  if (!form) {
    return;
  }

  // The DirectUploadsController included with the framework actually listens to click events,
  // otherwise it would have made more sense to broadcast an event or something.
  form
    .find('input[type=submit]')
    .first()
    .click();
});
