import $ from 'jquery';

$(() => {
  if ($('#admin_couriers_toggle_archived_couriers')?.length) {
    $(window).bind('pageshow', event => {
      if (event.originalEvent.persisted) {
        window.location.reload();
      }
    });

    $('#admin_couriers_toggle_archived_couriers').prop('disabled', false);

    $('#admin_couriers_toggle_archived_couriers').change(event => {
      $('#admin_couriers_toggle_archived_couriers').prop('disabled', true);
      const search = window.location.search.replace(
        /[?&]?view_archived_couriers=true[&]?/g,
        ''
      );
      if (event.target.checked) {
        window.location.search = `${search}${
          search ? '&' : '?'
        }view_archived_couriers=true`;
      } else {
        window.location.search = search;
      }
    });
  }
});
