import * as Sentry from '@sentry/react';

export default () => {
  Sentry.init({
    attachStacktrace: true,
    dsn: window.sentryFrontendDsn,
    environment: window.environment,
    release: window.sentryRelease,

    replaysSessionSampleRate: window.sentryReplaysSampleRate,
    replaysOnErrorSampleRate: window.sentryReplaysOnErrorSampleRate,

    tracesSampleRate: window.sentryTracesSampleRate,
    tracePropagationTargets: [
      // Uncomment the following line when testing locally:
      // 'localhost',
      /^https:\/\/.*\.(dispatchit\.com|dispatchfog\.org)/
    ],

    // This seems to require being last to get replays to work
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()]
  });

  Sentry.setUser({ id: window.user?.id });
};
