// This copies the information from a js-addressPrefill-selector select box and
// pastes it into the corresponding js-addressPrefill-* inputs

import $ from 'jquery';

class AddressPrefill {
  constructor(view) {
    this.$view = $(view);
    this.$view
      .find('.js-addressPrefill-selector')
      .on('change', this.fillForm.bind(this));
  }

  fillForm(e) {
    const optionSelected = $('option:selected', e.target);

    this.$view.find('.js-addressPrefill-name').val(optionSelected.data('name'));
    this.$view
      .find('.js-addressPrefill-street')
      .val(optionSelected.data('street'));
    this.$view.find('.js-addressPrefill-city').val(optionSelected.data('city'));
    this.$view
      .find('.js-addressPrefill-state')
      .val(optionSelected.data('state'));
    this.$view
      .find('.js-addressPrefill-postal_code')
      .val(optionSelected.data('postal_code'));
    this.$view
      .find('.js-addressPrefill-phone_number')
      .val(optionSelected.data('phone_number'));

    // Publish event that prefill is complete
    this.$view.trigger('prefill-complete');
  }
}

$(document).ready(() => {
  // Initialize address prefill views
  $('.js-addressPrefill').each((i, element) => {
    new AddressPrefill(element);
  });
});

export default AddressPrefill;
