const check_adjustment = (
  input,
  originalValue,
  message,
  compareFunc,
  isError
) => {
  var newVal = input.value;
  var $alert = $(input)
    .parent()
    .find('.alert');
  var alertTemplate = message => {
    const alertClass = isError ? 'alert-danger' : 'alert-info';
    return `<div class="alert ${alertClass} p-2 mt-4">${message}</div>`;
  };
  var compare = compareFunc
    ? compareFunc
    : () => {
        return newVal > originalValue * 2 || newVal < originalValue / 2;
      };

  // this can be removed if claims are handled differently
  if (compare()) {
    if ($alert.length) return;

    // uncomment this if we still go with the
    // below zero value check
    // if (Math.sign(input.value) === -1) {
    //   $alert.remove();

    //   var belowZeroMessage = 'You may not adjust the amount charged below 0.';

    //   $(input)
    //     .parent()
    //     .append(alertTemplate(message ?? belowZeroMessage));
    //   $('#adjustment-form-save-button').prop('disabled', true);
    // } else if (compare()) {
    //   $alert.remove();

    var defaultMessage =
      'You’ve entered a significant adjustment. Please double check that it is accurate.';

    $(input)
      .parent()
      .append(alertTemplate(message ?? defaultMessage));
    // $('#adjustment-form-save-button').prop('disabled', false);
  } else {
    $alert.remove();
    // $('#adjustment-form-save-button').prop('disabled', false);
  }
};

export default check_adjustment;
