import $ from 'jquery';

$(document).ready(() => {
  $('.admin_custom_setting_form').submit(() => {
    if ($('.js-overwrite-children-settings-checkbox').prop('checked')) {
      return confirm(
        'The settings will overwrite all settings on children organizations. Are you sure?'
      );
    } else if ($('.js-overwrite-children-settings-checkbox').length) {
      return confirm(
        'The settings will not change any settings on children organizations. Are you sure?'
      );
    } else {
      return true;
    }
  });
});
