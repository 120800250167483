import 'jquery';
import Cookies from 'js-cookie';

$(() => {
  $('#dismiss_persistent_banner').on('click', event => {
    let inTenMinutes = new Date(new Date().getTime() + 10 * 60 * 1000);

    Cookies.set(`dismissed_${event.target.dataset.persistentBanner}`, true, {
      expires: inTenMinutes
    });
  });
});
