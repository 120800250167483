import $ from 'jquery';

$(document).ready(function() {
  $('[data-toggle="tooltip"]').tooltip({
    classes: {
      'ui-tooltip': 'eta-tooltip-container',
      'ui-tooltip-title': 'eta-tooltip'
    }
  });
});
