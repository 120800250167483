import 'jquery';
import AdjustmentAmount from './AdjustmentAmount';
import { debounce } from 'lodash';
import check_adjustment from './check_adjustment';

class PriceAdjustmentForm {
  constructor(view) {
    this.adjustment_amount = new AdjustmentAmount();
    this.$view = $(view);
    this.original_amount_value;
    this.original_driver_amount_value;
    this.target_div = this.$view[0];

    $('#adjustment-config').on(
      'change',
      '#adjustment-reason-select',
      reason => {
        this.reason_change(reason);
      }
    );

    $('#adjustment-config').on('click', '#adjustment-form-cancel-button', e => {
      this.cancel_adjustment(e);
    });

    $('#adjustment-config').on(
      'click',
      '#adjustment_labor_fee_button_option',
      e => {
        this.precise_weight_change(e);
      }
    );

    $('#adjustment-config').on(
      'click',
      '#adjustment_mileage_change_button_option',
      e => {
        this.location_mileage(e);
      }
    );

    $('#adjustment-config').on(
      'click',
      '#adjustment_return_trip_button_option',
      e => {
        this.location_mileage(e);
      }
    );

    $('#adjustment-config').on(
      'input',
      '#adjustment-amount-input',
      debounce(this.handle_adjustment_change, 500)
    );

    $('#adjustment-config').on(
      'input',
      '#adjustment-driver-amount-input',
      debounce(this.handle_adjustment_driver_change, 500)
    );

    $('#adjustment-config').on(
      'input',
      '#order_price_adjustment_labor_fee_input',
      debounce(this.handle_adjustment_precise_weight, 500)
    );

    this.create_observer();
  }

  handle_adjustment_change = e => {
    check_adjustment(e.target, this.original_amount_value);
  };

  handle_adjustment_driver_change = e => {
    check_adjustment(e.target, this.original_driver_amount_value);
  };

  handle_adjustment_precise_weight = e => {
    const maxWeight = $(e.target).attr('max') ?? 12500;
    const isInvalid = () => {
      const preciseWeight = Number(e.target.value);
      return (
        !e.target.value ||
        Number.isNaN(preciseWeight) ||
        preciseWeight < 0 ||
        preciseWeight > maxWeight
      );
    };

    if (isInvalid()) {
      $('#adjustment_labor_fee_button_option').addClass('disabled');
      $('#adjustment-form-save-button').prop('disabled', true);
    } else {
      $('#adjustment_labor_fee_button_option').removeClass('disabled');
      $('#adjustment-form-save-button').prop('disabled', false);
    }

    check_adjustment(
      e.target,
      null,
      `Total weight must be between 0 and ${maxWeight}`,
      isInvalid,
      true
    );
  };

  create_observer = () => {
    var og_amount;
    var og_driver_amount;
    var observer = new MutationObserver(mutations => {
      $.each(mutations, function(i, mutation) {
        var addedNodes = $(mutation.addedNodes);
        var selector = '#adjustment-amount-input';
        var selector2 = '#adjustment-driver-amount-input';
        addedNodes
          .find(selector)
          .addBack(selector)
          .each(function() {
            og_amount = $(this).val();
          });
        addedNodes
          .find(selector2)
          .addBack(selector2)
          .each(function() {
            og_driver_amount = $(this).val();
          });
      });
      this.original_amount_value = og_amount;
      this.original_driver_amount_value = og_driver_amount;
    });
    if (this.target_div) {
      observer.observe(this.target_div, { childList: true, subtree: true });
    }
  };

  reason_change = reason => {
    this.adjustment_amount.set_values(
      reason.target.value,
      $('#order_price_adjustment_order_id').val(),
      $('#order_price_adjustment_mileage_change_input').val(),
      $('#order_price_adjustment_labor_fee_input').val()
    );

    $('#adjustment-amount-input').val(this.original_amount_value);
    $('#adjustment-driver-amount-input').val(this.original_driver_amount_value);
    if (this.adjustment_amount.reason === 'misc') {
      this.enable_input_fields([
        '#adjustment-amount-input',
        '#adjustment-driver-amount-input'
      ]);
    } else {
      this.disable_input_fields([
        '#adjustment-amount-input',
        '#adjustment-driver-amount-input'
      ]);
    }
    this.adjustment_amount.process();
    this.$view.find('.alert').remove();
  };

  cancel_adjustment = e => {
    e.preventDefault();
    $('#adjustment-form-target').empty();
  };

  precise_weight_change = e => {
    e.preventDefault();
    this.adjustment_amount.confirm_precise_weight();
  };

  location_mileage = e => {
    e.preventDefault();
    this.adjustment_amount.confirm_mileage();
  };

  enable_input_fields = input_fields => {
    input_fields.forEach(input => {
      $(`${input}`).prop('readonly', false);
    });
  };

  disable_input_fields = input_fields => {
    input_fields.forEach(input => {
      $(`${input}`).prop('readonly', true);
    });
  };
}

$(() => {
  $('#adjustment-config').each((i, element) => {
    new PriceAdjustmentForm(element);
  });
});
