$(() => {
  // checks for 10 numbers in exact format 123-123-1234
  const strictValidPhoneNumber = number =>
    /(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}/.test(number);

  // checks for any 10 numbers with any delimiters 1231231234
  const looseValidPhoneNumber = number =>
    /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/.test(number);

  const formatPhoneNumber = number => {
    let isNumber = num =>
      ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(num);
    let filtered = number.split('').filter(val => isNumber(val));
    return (
      filtered.slice(0, 3).join('') +
      '-' +
      filtered.slice(3, 6).join('') +
      '-' +
      filtered.slice(6).join('')
    );
  };

  $('.js-sms-tokenfield').on('tokenfield:createtoken', e => {
    if (looseValidPhoneNumber(e.attrs.value)) {
      let new_number = formatPhoneNumber(e.attrs.value);
      e.attrs.value = new_number;
      e.attrs.label = new_number;
    } else {
      $(e.relatedTarget).addClass('invalid');
    }
  });

  $('.js-sms-tokenfield').on('tokenfield:createdtoken', e => {
    if (!strictValidPhoneNumber(e.attrs.value)) {
      $(e.relatedTarget).addClass('invalid');
    }
  });

  $('.js-sms-tokenfield').tokenfield({
    createTokensOnBlur: true
  });
});

// Properly validates and reformats inputs below to 123-123-1234
// 1231231234
// (123)1231234
// 123-123-1234
// 123-1231234
// (123) 123-1234
// (123)123-1234

// 123123123 - fails
// 411 - fails
// (123)123-123 - fails
// 123-123-123 - fails
