$(() => {
  const TIMEOUT = 2000;

  const copySuccess = el => {
    el.classList.add('success');
    setTimeout(() => {
      el.classList.remove('success');
    }, TIMEOUT);
  };

  const copyFailure = el => {
    el.classList.add('failure');
    setTimeout(() => {
      el.classList.remove('failure');
    }, TIMEOUT);
  };

  // Copies the value of a data-copy attr on an element on click
  $(document).on('click', '[data-copy]', e => {
    const target = e.currentTarget;
    const text = $(target).data('copy');

    // Create textarea to copy text from, then remove it
    const el = document.createElement('textarea');
    el.className = 'accessibly-hidden';
    el.value = text;
    el.setAttribute('readonly', '');
    document.body.appendChild(el);
    el.select();

    try {
      // document.execCommand is deprecated, but may be needed for browser compatibility
      if (!navigator.clipboard) {
        document.execCommand('copy');
      } else {
        navigator.clipboard.writeText(text);
      }
      copySuccess(target);
    } catch (err) {
      copyFailure(target);
    }

    document.body.removeChild(el);
  });
});
