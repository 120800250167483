// TODO: whoever works on this file next, delete this comment block
//       to re-enable eslint for this file and fix the issues
//
/* eslint-disable react/prop-types */

import $ from 'jquery';
import React from 'react';
import Autocomplete from 'react-autocomplete';
import fuzzySearch from './lib/fuzzySearch';

class PricingZoneMarketAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      marketResults: [],
      filteredMarketResults: [],
      inputValue: props.inputValue,
      shouldDisable: props.shouldDisable
    };
  }

  componentDidMount() {
    $.get(`/admin/pricing_zones/market_filter`).then(results => {
      this.setState({
        marketResults:
          results.map(item => {
            return {
              ...item,
              type: 'marketResult',
              inputValue: item.name
            };
          }) || []
      });
    });
  }

  items = () => {
    return this.state.filteredMarketResults.map(item => {
      return { ...item, type: 'marketResult', inputValue: item.name };
    });
  };

  onChange = e => {
    const inputValue = e.target.value;
    if (!inputValue || inputValue.length < 2) {
      this.setState({
        inputValue,
        filteredMarketResults: []
      });
      return;
    }

    const filtered = this.getFilteredMarketList(inputValue);
    this.setState({
      inputValue: inputValue,
      filteredMarketResults: filtered
    });
  };

  getFilteredMarketList = inputValue => {
    let filtered = this.state.marketResults.filter(org =>
      fuzzySearch(inputValue, org.name)
    );
    return filtered.slice(0, 100);
  };

  onSelect = (value, item) => {
    this.setState({ inputValue: value }, () =>
      this.props.onSelect(this.state.inputValue, item)
    );
  };

  renderItem = (item, isHighlighted) => {
    const highlightedClass = isHighlighted ? 'active' : '';
    return (
      <div
        className={`autocomplete-menu-item autocomplete-menu-selectable ${highlightedClass}`}
        key={item.id}
      >
        <div>
          <div className="autocomplete-menu-item-icon" />
          <div className="autocomplete-menu-item-contents">
            <span>{item.name}</span>
          </div>
        </div>
      </div>
    );
  };

  renderMenu = items => {
    // Return empty div if there's nothing to see
    if (!items || items.length < 1) {
      return <div />;
    }

    return (
      <div className="autocomplete-menu org-referral-autocomplete-menu">
        {items}
      </div>
    );
  };

  // Overrides the focus event of the input element, so that we can manually
  // trigger a change.  Otherwise the autocomplete shows the default results
  // until user starts typing
  onFocus = e => {
    this.onChange(e);
  };

  onBlur = () => {
    // we only want to process this logic if there are no results
    if (this.items() && this.items().length > 0) {
      return;
    }

    let item_stub = { id: 0 };
    if (this.state.inputValue.length === 0) {
      item_stub = undefined;
    }

    // tell the caller that nothing was selected
    this.props.onSelect(this.state.inputValue, item_stub);
  };

  render() {
    return (
      <Autocomplete
        getItemValue={item => {
          return item.inputValue;
        }}
        items={this.state.filteredMarketResults}
        renderItem={this.renderItem}
        inputProps={{
          className: this.props.inputClasses,
          name: this.props.inputName,
          placeholder: this.props.placeholder,
          onFocus: this.onFocus,
          onBlur: this.onBlur,
          disabled: this.state.shouldDisable
        }}
        // Override the autoComplete attribute to something
        renderInput={props => {
          return (
            <input
              {...props}
              autoComplete={this.props.autoCompleteValue || 'off'}
            />
          );
        }}
        wrapperStyle={{}}
        wrapperProps={{ className: 'autocomplete' }}
        value={this.state.inputValue}
        onChange={this.onChange}
        onSelect={this.onSelect}
        renderMenu={this.renderMenu}
        autoHighlight={true}
        selectOnBlur={true}
      />
    );
  }
}

export default PricingZoneMarketAutocomplete;
