import 'jquery';

$(() => {
  let activeOrganizations = $('.js-active-org');
  let inactiveOrganizations = $('.js-inactive-org');
  let view_inactive_button = $('#inactive_org_view_button');
  let view_active_button = $('#active_org_view_button');
  let isActiveViewable = true;

  inactiveOrganizations.hide();

  view_inactive_button.on('click', () => {
    if (isActiveViewable) {
      activeOrganizations.hide();
      inactiveOrganizations.show();
      isActiveViewable = false;
    }
  });

  view_active_button.on('click', () => {
    if (!isActiveViewable) {
      activeOrganizations.show();
      inactiveOrganizations.hide();
      isActiveViewable = true;
    }
  });
});
