import $ from 'jquery';

$(() => {
  if ($('#toggle_deactivated_users')?.length) {
    $(window).bind('pageshow', event => {
      if (event.originalEvent.persisted) {
        window.location.reload();
      }
    });

    $('#toggle_deactivated_users').prop('disabled', false);

    $('#toggle_deactivated_users').change(event => {
      $('#toggle_deactivated_users').prop('disabled', true);
      const search = window.location.search.replace(
        /[?&]?view_deactivated_users=true[&]?/g,
        ''
      );
      if (event.target.checked) {
        window.location.search = `${search}${
          search ? '&' : '?'
        }view_deactivated_users=true`;
      } else {
        window.location.search = search;
      }
    });
  }
});
