$(() => {
  // Form effects for volume discounts

  var $selectEl = $('.js-vol-discounts-template');
  var $creditEl = $('.js-vol-discounts-credit');
  var $startEl = $('.js-vol-discounts-start');
  var $endEl = $('.js-vol-discounts-end');

  $($selectEl).on('change', e => {
    var target = e.target;
    var $selectedOption = $(target).find(':selected');
    var credit = $selectedOption.data('credit');
    var start = $selectedOption.data('start');
    var end = $selectedOption.data('end');

    $creditEl.text(credit ?? '--');
    $startEl.text(start ?? '--');
    $endEl.text(end ?? '--');
  });
});
