import 'jquery';

$(() => {
  $('[data-collapsable-hide-target]').each((index, item) => {
    let selector = $(item).data('collapsable-hide-target');
    $(item).on('click', () => {
      $(selector).addClass('hidden');
    });
  });

  $('[data-collapsable-show-target]').each((index, item) => {
    let selector = $(item).data('collapsable-show-target');
    $(item).on('click', () => {
      $(selector).removeClass('hidden');
    });
  });

  $('[data-collapsable-toggle-target]').each((index, item) => {
    let selector = $(item).data('collapsable-toggle-target');
    $(item).on('click', e => {
      e.preventDefault();
      $(selector).toggleClass('hidden');
    });
  });
});
