document.addEventListener('DOMContentLoaded', () => {
  const emailFields = document.querySelectorAll('.js-email-validation');

  emailFields.forEach(emailField => {
    // Create the error message element
    const emailError = document.createElement('span');
    emailError.classList.add(
      'text-danger',
      'float-right',
      'js-email-validation-error'
    );

    const parentDiv = emailField.parentElement;
    parentDiv.parentNode.insertBefore(emailError, parentDiv);

    // Add event listener to the email field
    emailField.addEventListener('input', () => {
      const emailValue = emailField.value;
      if (!validateEmail(emailValue)) {
        emailError.textContent = 'Invalid email';
      } else {
        emailError.textContent = '';
      }
    });
  });

  function validateEmail(email) {
    // Regular expression to check for a valid email with a top-level domain
    const re = /^.+@.+\..+$/;
    return email === '' || re.test(email);
  }
});
