// TODO: whoever works on this file next, delete this comment block
//       to re-enable eslint for this file and fix the issues
//
/* eslint-disable no-unused-vars */

import $ from 'jquery';
import React from 'react';
import ReactDom from 'react-dom';

import CoverageAreaMap from './components/CoverageAreaMap';

class CoverageAreaMapForm {
  constructor(view) {
    this.$view = $(view);

    this.coverageAreas = JSON.parse(
      this.$view.attr('data-coverageareamapform-coverageareas') || '[]'
    );
    this.focusedCoverageAreaId = this.$view.attr(
      'data-coverageareamapform-focusedcoverageareaid'
    );
    this.map = this.$view.find('.js-CoverageAreaMapForm-map')[0];
    this.boundaryField = this.$view.find(
      '.js-CoverageAreaMapForm-boundaryField'
    );
    this.nameField = this.$view.find('.js-CoverageAreaMapForm-nameField');
    this.validationEndpoint = this.$view.attr(
      'data-coverageareamapform-validationendpoint'
    );
    this.shouldPrefillWithCoverageAreaName =
      this.$view.attr(
        'data-coverageareamapform-prefillwithcoverageareaname'
      ) === 'true';

    this.syncBoundaryToField = this.syncBoundaryToField.bind(this);
    this.editBoundaryClicked = this.editBoundaryClicked.bind(this);

    this.$view.on(
      'click',
      '.js-CoverageAreaMapForm-editboundaries',
      this.editBoundaryClicked
    );

    this.renderComponent();
    if (this.shouldPrefillWithCoverageAreaName) {
      this.prefillWithCoverageAreaName();
    }
  }

  editBoundaryClicked(e) {
    e.preventDefault();
    this.editBoundariesForFocused = !this.editBoundariesForFocused;
    this.renderComponent();
  }

  prefillWithCoverageAreaName() {
    const name = this.nameField.val();
    const Geocoder = new google.maps.Geocoder();
    Geocoder.geocode({ address: name }, (results, status) => {
      // console.log(results, status);
      if (status === 'OK') {
        const ne = results[0].geometry.bounds.getNorthEast().toJSON();
        const sw = results[0].geometry.bounds.getSouthWest().toJSON();
        const geo = results[0].geometry.location;

        const prefilledCoverageAreaPath = [
          { lat: geo.lat() - 0.4, lng: geo.lng() - 0.7 },
          { lat: geo.lat() - 0.4, lng: geo.lng() + 0.7 },
          { lat: geo.lat() + 0.4, lng: geo.lng() + 0.7 },
          { lat: geo.lat() + 0.4, lng: geo.lng() - 0.7 }
        ];

        this.coverageAreas.push({
          coverage_area_name: name,
          coverage_area_id: 'PREFILLED',
          coverage_area_boundaries: prefilledCoverageAreaPath
        });
        this.focusedCoverageAreaId = 'PREFILLED';
        this.editBoundariesForFocused = true;
        this.renderComponent();
        this.syncBoundaryToField(prefilledCoverageAreaPath);
      }
    });
  }

  renderComponent() {
    ReactDom.render(
      <CoverageAreaMap
        coverageAreas={this.coverageAreas}
        focusedCoverageAreaId={this.focusedCoverageAreaId}
        editBoundariesForFocused={this.editBoundariesForFocused}
        onCoverageAreaBoundaryChange={this.syncBoundaryToField}
        mapError={this.mapError}
      />,
      this.map
    );
  }

  syncBoundaryToField(boundaries) {
    const boundariesSerialized = JSON.stringify(boundaries);

    this.boundaryField.val(boundariesSerialized);

    fetch(this.validationEndpoint, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': this.$view.find('[name="authenticity_token"]').val()
      },
      body: JSON.stringify({
        coverage_area: {
          boundary_coordinates: boundariesSerialized
        }
      })
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.result === 'success') {
          this.mapError = null;
        } else {
          this.mapError = 'The boundaries are invalid';
        }
        this.renderComponent();
      });
  }
}

// Initialize map views
$(document).ready(() => {
  $('.js-CoverageAreaMapForm').each(function(i, element) {
    var map = new CoverageAreaMapForm(element);
  });
});
