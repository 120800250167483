// Provide ability to check address with the Google Geocoding APIs, in order
// to retrieve and set geocoordinates and place_id in the record.

import $ from 'jquery';
import _ from 'lodash';
import getAddressObjectFromPlace from './googleUtils/getAddressObjectFromPlace';

class AddressGeocode {
  constructor(view) {
    this.$view = $(view);
    this.allowAddressOverride = this.$view.data('allow-address-override');
    this.notesFieldReference = this.$view.data('notes-field-reference');

    this.geocodeIfAddressFilled = this.geocodeIfAddressFilled.bind(this);
    this.geocodeDebounced = _.debounce(this.geocodeIfAddressFilled, 500);
    this.onItemInput = this.onItemInput.bind(this);
    this.fixAddress = this.fixAddress.bind(this);
    this.confirmUseGoogleResult = this.confirmUseGoogleResult.bind(this);

    this.$view.on('input', '.js-geocode-item', this.onItemInput);
    this.$view.on(
      'prefill-complete',
      '.js-addressPrefill',
      this.geocodeIfAddressFilled
    );
    this.$view.on('click', '.js-fixAddress', this.fixAddress);
    this.$view.on('click', '.js-confirmAddress', this.confirmUseGoogleResult);

    // Go ahead and geocode the addresses if they are already filled in on
    // pageload (ie error submissions).
    this.geocodeIfAddressFilled();
  }

  onItemInput() {
    this.hideMessages();
    if (this.formIsFilled()) {
      this.spinnerShow();
    }
    this.geocodeDebounced();
  }

  geocodeIfAddressFilled() {
    this.hideMessages();
    this.spinnerHide();
    if (this.formIsFilled() === true) {
      this.geocodeAddress();
    } else {
      this.clearGeocodingResult();
    }
  }

  formIsFilled() {
    var itemsNotFilled = this.$view.find('.js-geocode-item').filter(function() {
      return !this.value;
    });

    return itemsNotFilled.length === 0;
  }

  geocodeAddress() {
    this.spinnerShow();
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { address: this.addressString() },
      this.handleGeocodingResult.bind(this)
    );
  }

  addressString() {
    return (
      this.$view.find('.js-geocode-street').val() +
      ', ' +
      this.$view.find('.js-geocode-city').val() +
      ', ' +
      this.$view.find('.js-geocode-state').val() +
      ' ' +
      this.$view.find('.js-geocode-postal_code').val() +
      ', ' +
      this.$view.find('.js-geocode-country').val()
    );
  }

  clearGeocodingResult() {
    this.clearResultingFields();
    this.hideMessages();
    this.spinnerHide();
    this.$view.trigger('location-cleared');
  }

  hideMessages() {
    this.$view.find('.js-geocode-success').addClass('hidden');
    this.$view.find('.js-geocode-error').addClass('hidden');
    this.$view.find('.js-geocode-info').addClass('hidden');
  }

  spinnerShow() {
    this.$view.find('.js-geocode-spinner').removeClass('hidden');
  }

  spinnerHide() {
    this.$view.find('.js-geocode-spinner').addClass('hidden');
  }

  handleGeocodingResult(results, status) {
    this.clearResultingFields();
    this.hideMessages();
    this.spinnerHide();

    if (status === 'OK') {
      const result = results[0];
      const resultAddress = getAddressObjectFromPlace(result);
      this.$view.data('googleResult', result);
      this.$view.data('addressObject', resultAddress);

      if (
        result.partial_match === undefined ||
        result.partial_match === false ||
        this.addressMatchesForm(resultAddress)
      ) {
        this.$view
          .find('.js-geocode-success')
          .html(this.geocodeSuccessMessage());
        this.$view.find('.js-geocode-success').removeClass('hidden');
        this.setResultingFields({
          place_id: result.place_id,
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng()
        });
      } else {
        this.$view
          .find('.js-geocode-error')
          .html(this.geocodePartialMatchMessage(result.formatted_address));
        this.$view.find('.js-geocode-error').removeClass('hidden');

        this.setResultingFields({
          place_id: null,
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng()
        });
      }
    } else {
      this.$view.find('.js-geocode-error').html(this.geocodeErrorMessage());
      this.$view.find('.js-geocode-error').removeClass('hidden');
    }
  }

  confirmUseGoogleResult(e) {
    e.preventDefault();

    this.hideMessages();
    this.spinnerHide();
    this.setResultingFields({
      place_id: this.$view.data('googleResult').place_id,
      lat: this.$view.data('googleResult').geometry.location.lat(),
      lng: this.$view.data('googleResult').geometry.location.lng()
    });

    this.$view
      .find('.js-geocode-info')
      .html(
        this.geocodeOverriddenAddressMessage(
          this.$view.data('googleResult').formatted_address
        )
      );

    this.$view.find('.js-geocode-info').removeClass('hidden');
  }

  addressMatchesForm(address) {
    const street = _.join(
      _.compact([address['street_number'], address['street_name']]),
      ' '
    );

    return (
      this.$view.find('.js-geocode-street').val() === street &&
      this.$view.find('.js-geocode-city').val() === address['city'] &&
      this.$view.find('.js-geocode-state').val() === address['state'] &&
      this.$view.find('.js-geocode-postal_code').val() === address['zip']
    );
  }

  fixAddress(e) {
    e.preventDefault();
    this.hideMessages();

    var addressObject = this.$view.data('addressObject');

    var street = _.join(
      _.compact([addressObject['street_number'], addressObject['street_name']]),
      ' '
    );

    this.$view.find('.js-geocode-street').val(street);
    this.$view.find('.js-geocode-city').val(addressObject['city']);
    this.$view.find('.js-geocode-state').val(addressObject['state']);
    this.$view.find('.js-geocode-postal_code').val(addressObject['zip']);
    this.$view.find('.js-geocode-country').val(addressObject['country']);

    this.geocodeIfAddressFilled();
  }

  setResultingFields(result) {
    this.$view.find('.js-geocode-google_place_id').val(result.place_id);
    this.$view.find('.js-geocode-geo_lat').val(result.lat);
    this.$view.find('.js-geocode-geo_lng').val(result.lng);
    this.$view.trigger('geocode-complete', result);
  }

  clearResultingFields() {
    this.$view.find('.js-geocode-google_place_id').val('');
    this.$view.find('.js-geocode-geo_lat').val('');
    this.$view.find('.js-geocode-geo_lng').val('');
  }

  geocodeSuccessMessage() {
    return `
      <p>
        <span class="glyphicon glyphicon-ok-circle"></span> <strong>Location was found.</strong>
      </p>
    `;
  }

  geocodeErrorMessage() {
    return `
      <p>
        <span class="glyphicon glyphicon-remove-circle"></span> <strong>The address is not valid. Please try again.</strong>
      </p>
    `;
  }

  geocodePartialMatchMessage(addressText) {
    var message = `
      <p>Did you mean <strong>${addressText}</strong>?</p>
      <p>
        <a href="#" class="js-fixAddress btn btn-danger">Yes, use that address</a>
      </p>
    `;

    if (this.allowAddressOverride) {
      message += `
        <br>
        <p>If the address entered above is correct and the location on the map is accurate, you can choose to proceed with the address as entered.</p>
        <p>Please include clarifying comments for the driver in the <a href="${this.notesFieldReference}" class="link">Notes</a> field.</p>
        <p><a href="#" class="js-confirmAddress btn btn-default">Proceed with the address as entered</a></p>
      `;
    }

    return message;
  }

  geocodeOverriddenAddressMessage(addressText) {
    return `
      <p>
        <span class="glyphicon glyphicon-info-sign"></span> This address was not verified by Google. Please include clarifying comments for the driver in the <a href="#order_notes" class="link">Order Notes</a> field.
      </p>
      <p>The suggested address was <strong>${addressText}</strong>.</p>
      <p>
        <a href="#" class="js-fixAddress btn btn-default">Switch to that address</a>
      </p>
    `;
  }
}

// Initialize Address Geocoding views
$(document).ready(() => {
  $('.js-geocode').each((i, element) => {
    new AddressGeocode(element);
  });
});
