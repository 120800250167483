import 'jquery';

$(() => {
  $('.js-editable').each((index, view) => {
    const $view = $(view);
    const $showView = $view.find('.js-editable-showView');
    const $editView = $view.find('.js-editable-editView');

    // if any elements inside the view have an error, then show the edit view.
    if ($view.find('.has-error').length > 0) {
      $showView.addClass('hidden');
      $editView.removeClass('hidden');
    }

    $view.on('click', '.js-editable-editButton', e => {
      e.preventDefault();
      $showView.addClass('hidden');
      $editView.removeClass('hidden');
    });

    $view.on('click', '.js-editable-cancelButton', e => {
      e.preventDefault();
      $showView.removeClass('hidden');
      $editView.addClass('hidden');
    });
  });
});
