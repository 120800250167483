import 'jquery';

$(() => {
  $('#driver_is_staff_driver_visual').on('change', e => {
    $('#driver_form_bits_post_driver_type').removeClass('hidden');
    switch (e.target.value) {
      case 'Independent':
        $('#driver_is_staff_driver').val(false);
        $('#driver_user_attributes_organization_id').prop('disabled', false);
        $('#driver_user_attributes_organization_id').val('');
        $('#driver_is_staff_pay_via_stripe').removeClass('hidden');
        $('#driver_is_staff_zenefits_uid').addClass('hidden');
        break;
      default:
        $('#driver_is_staff_driver').val(false);
        $('#driver_form_bits_post_driver_type').addClass('hidden');
        $('#driver_is_staff_pay_via_stripe').addClass('hidden');
        $('#driver_is_staff_zenefits_uid').removeClass('hidden');
        break;
    }
  });
});
