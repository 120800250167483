import $ from 'jquery';

$(function() {
  $('a[id^="edit-pricing-event-link-"]').on('click', function(e) {
    e.preventDefault();
    const eventId = this.id.replace('edit-pricing-event-link-', '');
    const editRow = $(`#edit-pricing-event-row-${eventId}`);
    if (editRow.length === 0) {
      return;
    }

    editRow.is(':visible') ? editRow.hide() : editRow.show();
  });
});
