import * as validator from 'card-validator';
import $ from 'jquery';
import 'jqueryMask';
import _ from 'lodash';

class CreditCardForm {
  constructor(view) {
    this.$view = $(view);
    this.$number = this.$view.find('.js-CreditCardForm-number');
    this.$brand = this.$view.find('.js-CreditCardForm-brand');
    this.$expiration = this.$view.find('.js-CreditCardForm-expiration');
    this.$cvv = this.$view.find('.js-CreditCardForm-cvv');

    this.$number.on('input', this.handleNumberChange.bind(this));

    const result = validator.number(this.$number.val());
    this.setNumberFieldMask(result.card);
    this.setBrand(result.card);
    this.setExpirationFieldMask();
  }

  handleNumberChange(e) {
    const result = validator.number($(e.target).val());
    this.setNumberFieldMask(result.card);
    this.setBrand(result.card);
    this.setCVVFieldMask(result.card);
  }

  setNumberFieldMask(card) {
    if (!card) {
      this.$number.unmask();
      return;
    }

    const length = _.last(card.lengths);
    let mask = new Array(length).fill('0');

    card.gaps.forEach((gap, index) => {
      mask.splice(gap + index, 0, ' ');
    });
    this.$number.mask(mask.join(''));
  }

  setExpirationFieldMask() {
    this.$expiration.mask('00 / 00');
  }

  setCVVFieldMask(card) {
    if (!card || !card.code) {
      this.$cvv.unmask();
      return;
    }

    const mask = new Array(card.code.size).fill('0').join('');
    this.$cvv.mask(mask);
  }

  setBrand(card) {
    this.$number.removeClass('visa discover mastercard american_express');
    if (!card) {
      this.$brand.val('');
      return;
    }

    switch (card.type) {
      case 'visa':
      case 'discover':
        this.$brand.val(card.type);
        this.$number.addClass(card.type);
        break;
      case 'master-card':
        this.$brand.val('master');
        this.$number.addClass('mastercard');
        break;
      case 'american-express':
        this.$brand.val('american_express');
        this.$number.addClass('american_express');
        break;
      default:
      // do nothing
    }
  }
}

$(() => {
  // console.log('ready')

  // console.log($('.js-CreditCardForm-number').length);
  // $('.js-CreditCardForm-number').payment('formatCardNumber');
  $('.js-CreditCardForm').each((i, item) => {
    new CreditCardForm(item);
  });
});
