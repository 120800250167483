import 'jquery';

$(() => {
  $('.roles-checkbox').on('click', e => {
    let target = $(e.target)[0];
    let found_checkbox = $(e.target).siblings('.roles-checkbox')[0];
    let input = $(e.target).siblings('.roles-checkbox-input')[0];

    let handle_true_input = () => {
      $(input).attr('value', 'true');
      if ($(input).attr('name') === 'role_memberships[][staff_driver]') {
        let driver_needs_setup = $('.role-driver-link-staff_driver').hasClass(
          'role-driver-needs-setup'
        );
        if (driver_needs_setup) {
          $('.role-driver-link-staff_driver').removeClass('hidden');
        }
      } else if ($(input).attr('name') === 'role_memberships[][driver]') {
        let driver_needs_setup = $('.role-driver-link-staff_driver').hasClass(
          'role-driver-needs-setup'
        );
        if (driver_needs_setup) {
          $('.role-driver-link-driver').removeClass('hidden');
        }
      }
    };

    let handle_false_input = () => {
      $(input).attr('value', 'false');
      if ($(input).attr('name') === 'role_memberships[][staff_driver]') {
        $('.role-driver-link-staff_driver').addClass('hidden');
      } else if ($(input).attr('name') === 'role_memberships[][driver]') {
        $('.role-driver-link-driver').addClass('hidden');
      }
    };

    if ($(target).hasClass('checkmark')) {
      if ($(found_checkbox).hasClass('checked')) {
        $(found_checkbox).addClass('unchecked');
        $(found_checkbox).removeClass('checked');
        handle_false_input();
      } else {
        $(found_checkbox).addClass('checked');
        $(found_checkbox).removeClass('unchecked');
        handle_true_input();
      }
    } else {
      if ($(target).hasClass('checked')) {
        $(target).addClass('unchecked');
        $(target).removeClass('checked');
        handle_false_input();
      } else {
        $(target).addClass('checked');
        $(target).removeClass('unchecked');
        handle_true_input();
      }
    }
  });
});
