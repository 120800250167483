import 'jquery';
import createConsumer from './channels/consumer';

$(() => {
  $('.js-customer-edit').each((index, el) => {
    const $el = $(el);
    const $message = $el.find('.js-customer-edit-message');

    const disableEditing = () => {
      $el.removeClass('editable');
      $message.removeClass('editable');
    };

    const enableEditing = () => {
      $el.addClass('editable');
      $message.addClass('editable');
    };

    const orderEditChannel = createConsumer.subscriptions.create(
      {
        channel: 'OrderEditChannel',
        order_id: $el.data('order-id'),
        user_id: $el.data('user-id'),
        element_id: $el.data('element-id')
      },
      {
        received: data => {
          if (data.is_editable_status === true) {
            enableEditing();
          } else if (data.is_editable_status === false) {
            disableEditing();
          }
        }
      }
    );

    createConsumer.subscriptions.create(
      {
        channel: 'OrderChannel',
        id: $el.data('order-id')
      },
      {
        received: data => {
          if (data.check_editable_status === true) {
            orderEditChannel.perform('check_editable_status');
          }
        }
      }
    );
  });
});
