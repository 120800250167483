class HorizontalCardsFocus {
  constructor(view) {
    this.view = $(view);

    this.view.on('click', '.js-HorizontalCardsFocus-card', this.focusCard);
  }

  focusCard = e => {
    const card = $(e.target).closest('.js-HorizontalCardsFocus-card');

    if (!card || this.isElementInViewport(card[0])) {
      return;
    }

    const cardOffset = card[0].offsetLeft;
    const collectionWidth = this.view[0].offsetWidth;
    const cardWidth = card[0].offsetWidth;
    const amountToSubtract = (collectionWidth - cardWidth) / 2;

    this.view.animate({ scrollLeft: cardOffset - amountToSubtract }, 100);
  };

  isElementInViewport = el => {
    var rect = el.getBoundingClientRect();

    return (
      rect.left >= 0 &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };
}

$(document).ready(() => {
  $('.js-HorizontalCardsFocus').each((i, el) => {
    new HorizontalCardsFocus(el);
  });
});
