import check_adjustment from './check_adjustment';
import { debounce } from 'lodash';

class AdHocDriverPayments {
  constructor($element) {
    this.$el = $element;
    this.originalValue = this.$el.val();

    this.$el.on('input', debounce(this.handleAdjustmentChange, 500));
  }

  handleAdjustmentChange = e => {
    var newVal = e.target.value;
    var message =
      'You’ve entered a significant amount. Please double check that it is accurate.';
    var compareFunc = () => {
      return newVal > 100;
    };

    check_adjustment(e.target, this.originalValue, message, compareFunc);
  };
}

$(() => {
  $('.js-ad-hoc-driver-pay-amount').each((i, element) => {
    new AdHocDriverPayments($(element));
  });
});
