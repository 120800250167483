$(() => {
  const validateEmailHandler = e => {
    // regex from devise, which is very permissable, only validates there's
    // a @ in the middle
    const re = /^[^@]+@[^@]+$/;
    const valid = re.test(e.attrs.value);
    if (!valid) {
      $(e.relatedTarget).addClass('invalid');
    }
  };

  $('.js-email-tokenfield')
    .on('tokenfield:createdtoken', validateEmailHandler)
    .tokenfield({
      createTokensOnBlur: true
    });

  $('.js-tokenfield').tokenfield({
    createTokensOnBlur: true
  });
});
