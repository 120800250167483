import $ from 'jquery';

$(document).ready(() => {
  $('.modal_div').on('click', '#pricing_zone_details_close_button', () => {
    $('.modal_div').toggle();
    $('.modal_container').toggle();
  });

  $('.view_pricing_config_details').on('click', () => {
    $('.modal_div').toggle();
    $('.modal_container').toggle();
  });
});
