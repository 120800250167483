// TODO: whoever works on this file next, delete this comment block
//       to re-enable eslint for this file and fix the issues
//
/* eslint-disable no-unused-vars */

import $ from 'jquery';
import PricingZoneMarketAutocomplete from './PricingZoneMarketAutocomplete';
import React from 'react';
import ReactDOM from 'react-dom';

class PricingZoneMarketInput {
  constructor(view) {
    this.$view = $(view);
    this.setFields();
    this.buildAutocomplete();
  }

  buildAutocomplete() {
    const inputClasses = this.$input.attr('class');
    const inputValue = this.$input.val();
    const inputName = this.$input.attr('name');
    const autoCompleteValue = this.$input.attr('data-autoComplete');
    const options = this.$view.data('options') || {};
    const placeholderField =
      this.$view.attr('data-placeholderField') || 'Type a market name';
    const shouldDisable = this.$input[0].disabled;

    this.$wrapper = $('<div></div>');
    this.$input.after(this.$wrapper);
    this.$input.remove();
    ReactDOM.render(
      <PricingZoneMarketAutocomplete
        inputClasses={inputClasses}
        inputValue={inputValue}
        inputName={inputName}
        shouldDisable={shouldDisable}
        autoCompleteValue={autoCompleteValue}
        placeholder={placeholderField}
        onSelect={(inputValue, item) => {
          if (item) {
            this.$market_id.val(item.id);
            return;
          }

          // there is no item, we want to clear the fields
          this.clearInput();
        }}
      />,
      this.$wrapper[0]
    );
  }

  setFields() {
    this.$input = this.$view.find('.js-PricingZoneMarketAutocomplete-input');
    this.$market_id = this.$view.find(
      '.js-PricingZoneMarketAutocomplete-market_id'
    );
  }

  clearInput() {
    this.$input.val('');
    this.$market_id.val('');
  }
}

$(() => {
  $('.js-PricingZoneMarketAutocomplete').each((i, item) => {
    new PricingZoneMarketInput(item);
  });
});
