import $ from 'jquery';

// Toggles bootstrap's collapse based on radio button selection
//
// Shows an element when its data-checked-toggle attribute matches the value of
// a checked radio button inside a .js-radio-toggle container:
//
// <div class="js-radio-collapse">
//   <input type="radio" name="foo" value="bar" />
//   <div class="collapse" data-radio-toggle="bar">Show this when the input is selected</div>
// </div>

$(document).ready(() => {
  const radioName = $('.js-radio-collapse input[type="radio"]')
    .first()
    .attr('name');
  const $radios = $(`[name="${radioName}"]`);
  const $panels = $('[data-radio-toggle]');

  let checkedValue = '';

  $radios.each((i, radio) => {
    if (radio?.checked) {
      checkedValue = radio.value;
    }
  });

  if (checkedValue) {
    const $initialVisiblePanel = $(`[data-radio-toggle="${checkedValue}"]`);
    $initialVisiblePanel.collapse('show');
    $panels.not($initialVisiblePanel).collapse('hide');
  }

  $radios.on('change', e => {
    const value = e.target.value;
    const $visiblePanel = $(`[data-radio-toggle="${value}"]`);

    $visiblePanel.collapse('show');
    $panels.not($visiblePanel).collapse('hide');
  });
});
